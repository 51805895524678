import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAWQ_qHX2Iiuq_jONnaQaDk3DTg50qlS00",
    authDomain: "prayaas-44712.firebaseapp.com",
    projectId: "prayaas-44712",
    storageBucket: "prayaas-44712.firebasestorage.app",
    messagingSenderId: "976584595213",
    appId: "1:976584595213:web:01c722a62c9a0c7d000244",
    measurementId: "G-F20PLCT946"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };
